<template>
    <div class="page-body">
        <div class="material">
            <div class="material-wechat">
                <div class="wechat-box">
                    <div class="wechat-name">
                        五佳网络
                    </div>
                    <div :class="curIndex == -1 ? 'cover-box active':'cover-box'" @click="switchItem(-1)">
                        <a-image class="cover-image" :preview="false"
                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
                        <div class="cover-title">这里是图文标题</div>
                    </div>
                    <div class="content-list">
                        <div :class="curIndex == index ? 'item active':'item'" v-for="(item,index) in formState.list"
                            :key="index" @click="switchItem(index)">
                            <a-row>
                                <a-col :span="19">
                                    <div class="item-title">{{item.title}}</div>
                                </a-col>
                                <a-col :span="5">
                                    <a-image :preview="false" :src="item.image" />
                                </a-col>
                            </a-row>
                        </div>
                        <div class="add-item" @click="addItem()">
                            <PlusOutlined /><span class="add-item-txt">新建消息</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="material-editor">
                <a-form :model="formState" autocomplete="off" :label-col="labelCol" :wrapper-col="wrapperCol"
                    @finish="onSubmit">
                    <a-form-item label="标题">
                        <a-input v-model:value="
                    formState.title
                  " />
                    </a-form-item>
                    <a-form-item label="摘要">
                        <a-textarea class="message-textarea" v-model:value="formState.description" />
                    </a-form-item>
                    <a-form-item label="封面">
                        <CsImage v-model:imgOne="formState.cover" />
                    </a-form-item>
                    <a-form-item label="作者">
                        <a-input v-model:value="formState.author" />
                    </a-form-item>
                    <a-form-item label="正文">
                        <a-textarea class="message-textarea" />
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div class="submit-bar">
            <a-button type="primary" html-type="submit" @click="onSubmit" class="submit-save-btn">保存并发布</a-button>
            <a-button class="submit-preview">预览</a-button>
        </div>
    </div>
</template>
<script>
    import {
        defineComponent,
        reactive,
        ref
    } from "vue";
    import {
        PlusOutlined
    } from "@ant-design/icons-vue";
    import "./style/material.less";
    import CsImage from "@/components/UpImage";
    export default defineComponent({
        components: {
            PlusOutlined,
            CsImage
        },
        setup() {
            const formState = reactive({
                title: "sss",
                cover: "",
                curIndex: -1,
                list: [{
                        title: "这里是标题一这里是标题一这里是标题一这里是标题一这里是标题一这里是标题一",
                        image: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    },
                    {
                        title: "这里是标题二",
                        image: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    },
                    {
                        title: "这里是标题三",
                        image: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    },
                ],
            });

            const addItem = () => {
                formState.list.push({
                    title: "这里是标题二",
                    image: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                });
            }

            const onSubmit = () => {

            }

            const curIndex = ref(-1);

            const switchItem = (index) => {
                curIndex.value = index
            }

            return {
                formState,
                onSubmit,
                addItem,
                curIndex,
                switchItem,
                labelCol: {
                    span: 3,
                },
                wrapperCol: {
                    span: 14,
                },
            };
        },
    });
</script>